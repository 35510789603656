<template>
 <div class="header-container">
    <!-- Barra superior com contatos e redes sociais -->
    <div class="top-bar">
      <div class="contact-info">
        
   
      <div class="social-icons">
        <span><i class="fas fa-phone"></i> (41) 3527-2420</span>
        <span><i class="fab fa-whatsapp"></i> (41) 99810-0456</span>
        <a href="https://www.facebook.com/collecttelemarketingsocial"><i class="fab fa-facebook"></i></a>
        <a href="https://www.instagram.com/collecttelemarketingsocial/"> <i class="fab fa-instagram"></i></a>
        <a href="https://collectsocial.com.br/contato/"><i class="fas fa-envelope"></i></a>
      </div>

    </div>
    </div>

    <!-- Barra inferior com logotipo e navegação -->
    <div class="submenu-bar">
      <div class="logo">
        <img src="@/assets/Logo-Collect.png" alt="Logo da empresa" />
      </div>
      
    </div>
  </div>

  <div class="donation-form">
    <div class="container">
      <div class="form-container">
        <h1>{{ textos.formulario.titulo }}</h1>
        <div class="donation-selection">
          <IssueCrm7 :currentStep="currentStep" :setDonation="setDonation" />
          <IssueCrm8 />
        </div>
      </div>
      <div class="info-box">
        <h2>{{ textos.formulario.infobox.infoboxTitulo }} <button v-if=ativo  class="mas-button" @click="info">+</button> <button v-else class="mas-button" @click="info">-</button></h2>
        <div v-if=ativo>
        <div v-for="(line, index) in infoboxArray" :key="index">
          <p v-html="line.replace(/\n/g, '<br><br>')"></p>
        </div>
      </div>
      
      </div>
    </div>
  </div>
  <!-- Rodapé -->
  <footer class="footer">
    <div class="footer-content">
      <img src="@/assets/Logo-Collect.png" alt="Logo UNICA" class="footer-logo">
      <div v-for="(footertexto, index) in footerArray" :key="index" class="footer-details">
        <p v-html="footertexto.replace(/\n/g, '<br>')"></p>
      </div>
    </div>
    <div class="copyright">
      &copy;{{textos.formulario.copyright}}
         </div>
  </footer>
</template>
<script>
import IssueCrm7 from './issuecrm7.vue';
import IssueCrm8 from './issuecrm8.vue';
import dados from '@/assets/dados.json';
export default {
 
  name: 'DonationForm',
  version: '1.0.0',
  createdDate: '2024-10-12',
  lastUpdated: '2024-10-14',
  data() {
    return {
      currentStep: 0, // Defina a propriedade currentStep aqui
      selectedDonation: null,
      textos: dados,
      infoboxArray: [],
      footerArray: [],
       ativo : true,
    };
  },

  created() {
    // Usando split para dividir a string em um array
    //this.infoboxArray = this.textos.formulario.infobox.infoboxtexto.split('|');
    //this.footerArray = this.textos.formulario.footer.split('|');

    // Verificando se infoboxtexto é uma string antes de fazer o split
    if (typeof this.textos.formulario.infobox.infoboxtexto === 'string') {
      this.infoboxArray = this.textos.formulario.infobox.infoboxtexto.split('|');
    } else {
      console.error('infoboxtexto não é uma string:', this.textos.formulario.infobox.infoboxtexto);
    }

    // Verificando se footer é uma string antes de fazer o split
    if (typeof this.textos.formulario.footer === 'string') {
      this.footerArray = this.textos.formulario.footer.split('|');
      console.log('footerArray:', this.footerArray); // Adicione este log para verificar o conteúdo de footerArray
    } else {
      console.error('footer não é uma string:', this.textos.formulario.footer);
    }

  },
  
  components: {

    IssueCrm7,
    IssueCrm8
  },
  methods: {
    setDonation(amount) {
      this.selectedDonation = amount;
      this.currentStep = amount; // Atualiza currentStep aqui
    },
    setOtherAmount() {
      // Lógica para lidar com um valor diferente
    },
    submitForm() {
      // Lógica para enviar o formulário
      console.log('Formulário enviado com os dados:', this.formData);
      console.log('Valor da doação selecionado:', this.selectedDonation);
    },

    info(){
      
        this.ativo = !this.ativo 
      
    }
  },
};


</script>
<style scoped>

/* Estilo para o topo */

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');


.header-container {
  width: 100%;
}

.top-bar {
  background-color: #36806D;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  color: #fff;
}

.contact-info span {
  margin-right: 30px;
}

.social-icons i {
  margin-left: 15px;
  color: #FFDD57; /* Cor amarela dos ícones */
}



/* Estilo para a barra inferior */
.submenu-bar {
  background-color: #52A28E;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  align-self: center;
}

  .logo,.social-icons , .contact-info {
  flex: 1; /* Permite que a logo ocupe um espaço flexível */
  display: flex; /* Usando flexbox para centralizar */
  justify-content: center; /* Centraliza horizontalmente */
}
.logo img {
  height: 90px;
}
/* Importa a fonte Roboto do Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');

/* Reset CSS para remover margens, preenchimentos e bordas */
label, h2,h1,p{
  font-family: 'Roboto', sans-serif; /* Fonte desejada */
  
  font-weight: 300; /* Peso da fonte mais fino */
  margin-bottom: 5px; /* Espaçamento abaixo do rótulo */
}

.info-box label, p{
  font-size: 12px; /* Tamanho de fonte ajustado */
}
h1{
  font-size: 18px; /* Tamanho de fonte ajustado */

}

h2{
  font-size: 16px; /* Tamanho de fonte ajustado */
}
html,
body {
  margin: 0;
  padding: 0;
  border: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Evita a barra de rolagem horizontal */
}

.donation-form {
  background-image: url('/src/assets/background.jpg'); /* Caminho para a imagem de fundo */
  background-attachment: fixed;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  padding: 20px;
  gap: 15px; /* Reduzi o espaçamento para telas menores */
  flex-wrap: wrap; /* Permite que os itens se ajustem em telas menores */
}

.form-container {
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px; /* Largura máxima para o formulário */
  margin-bottom: 20px; /* Espaço entre os elementos em telas menores */
}

.info-box {
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 200px; /* Largura máxima para a caixa de informação */
  margin-bottom: 20px; /* Espaço entre os elementos em telas menores */
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Garante que as caixas sejam alinhadas ao topo */
  margin-top: 20px;
  border: none;
  
  gap: 20px; /* Adiciona espaçamento entre os elementos quando estão lado a lado */
}

/* Media Query para telas menores que 720 pixels */
@media screen and (max-width: 720px) {
  .container {
    flex-direction: column; /* Reorganiza os elementos em uma coluna em telas menores */
    align-items: center; /* Centraliza os elementos na tela para uma aparência mais limpa */
  }

  .form-container, .info-box {
    width: 100%; /* Ajusta a largura para ocupar toda a tela em dispositivos móveis */
    max-width: 100%; /* Remove a largura máxima para garantir que o layout seja fluido */
    margin-left: 0; /* Remove a margem para evitar deslocamento lateral */
    margin-bottom: 20px; /* Adiciona espaçamento entre os elementos na visualização em coluna */
  }
}
/* FOOTER */
.footer {
  background-color: #52A28E;
  color: #fff;
  padding: 20px;
  margin-top: 20px; /* Espaço entre o conteúdo principal e o rodapé */
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Permite que o conteúdo se ajuste em telas menores */
  max-width: 1200px; /* Define uma largura máxima para centralizar o conteúdo */
  margin: 0 auto; /* Centraliza o conteúdo dentro do footer */
  padding: 20px; /* Espaçamento interno */
}

.footer-logo {
  width: 80px; /* Tamanho do logo ajustado para telas menores */
  margin-right: 20px; /* Espaçamento entre o logo e o conteúdo */
}

.footer-details {
  text-align: left;
  flex: 1; /* Faz com que a seção de detalhes ocupe o espaço disponível */
  margin-bottom: 15px; /* Espaço entre os detalhes do footer em telas menores */
}

.copyright {
  margin-top: 10px; /* Espaçamento superior para separar do restante */
  font-size: 12px; /* Tamanho menor para o texto de copyright em telas menores */
  color: #bbb; /* Cor mais suave para o texto */
}

#app,
.container,
.footer {
  margin: 0;
  padding: 0;
}

/* Media Query para dispositivos móveis */
@media screen and (max-width: 768px) {
  .form-container,
  .info-box {
    width: 100%; /* Ajusta a largura para preencher a tela em dispositivos móveis */
    max-width: none; /* Remove a largura máxima para dispositivos menores */
    margin: 10px 0; /* Espaçamento entre os blocos */
  }

  .footer-content {
    flex-direction: column; /* Muda a direção para coluna em telas pequenas */
    text-align: center; /* Centraliza o texto em dispositivos menores */
  }

  .footer-logo {
    margin: 0 auto 10px; /* Centraliza o logo e adiciona margem inferior */
   
  }
  .info-box .mas-button {
  background-color: #440a0a;
  /* Azul */
  color: white;
  border: none;
  padding: 4px 4px;
  border-radius: 4px;
  cursor: pointer;
}
}
</style>
