<template>
  <button class="donate-button" @click="redirectToDonationPage">
    Faça sua Doação
  </button>
</template>

<script>
export default {
  methods: {
    redirectToDonationPage() {
      window.location.href = '/donation'; // URL da página de doação
    }
  }
}
</script>

<style scoped>
.donate-button {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}
</style>