<template>
    <div>
      <Formulario />
    </div>
  </template>
  
  <script>
  import Formulario from '@/components/Formulario.vue';
  
  export default {
    components: {
      Formulario

    }
  }
  </script>