<template>
    <div>
      <h1>Bem-vindo ao CRMdoações!</h1>
      <DonationButton />
    </div>
  </template>
  
  <script>
  import DonationButton from '@/components/DonationButton.vue';
  
  export default {
    components: {
      DonationButton
    }
  }
  </script>